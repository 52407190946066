import {GraphNode} from "../../models/graph/GraphNode";
import * as _ from "lodash";
import {Question} from "../../models/questions/Question";

/**
 * Returns the given nodes by descending priority and does not mutate the given array. Example:
 *
 * input (priorities): 5, 10, 0, -5
 * output (priorities)
 *
 * It is assumed that nodes have the priority saved in (JSON path) .values.prio or .prio
 *
 * @param prioritized the nodes to sort
 * @returns {unknown[]} the list of sorted nodes
 */
const sortByPriority = <T extends GraphNode | Question>(prioritized: T[]): T[] => {
    //sort the nodes with descending priority (priority * (-1) and ascending)
    return _.sortBy(prioritized, [singleElement => {

        const assumedGraphNode = singleElement as GraphNode;

        // If it is a GraphNode
        if(assumedGraphNode.values)
            return -assumedGraphNode.values.prio

        // If it is a Question
        return -(singleElement as Question).prio;
    }])
}

export default sortByPriority;