import React from "react";
import {FeedbackToast} from "../FeedbackToast/FeedbackToast";
import QuestionCard from "../Questions/shared/QuestionCard/QuestionCard";

export const PageEmbeddedFeedbackPrompt = ({botId}) => {
    return <QuestionCard>
        <div>
            <FeedbackToast botId={botId}/>
        </div>
    </QuestionCard>;
}