const maraOrtlerPicture = require('./profile_pictures/Mara_Ortler.jpg');
const glcLogo = require('./profile_pictures/Goethe-Uni-Law-Clinic_Logo.gif');
const uniWuerzburgLogo = require('./profile_pictures/Lehrstuhl-InfoVI-Logo.png');

const _ = require('lodash');

const creditData = {
    "Mara Ortler": {
        name: "Mara Ortler",
        picture: maraOrtlerPicture,
        job: {
            "de_DE": "Rechtsanwältin, spe\u00ADzia\u00ADli\u00ADsiert im Mi\u00ADgra\u00ADti\u00ADons\u00ADrecht",
            "en_GB": "German Lawyer, specialised in migration law"
        },
        website: "https://unsere-anwaelte.de/mara-ortler/",
        instagram: "https://www.instagram.com/law_and_ortler/"
    },
    "GLC": {
        name: "Goethe-Uni-Law Clinic",
        picture: glcLogo,
        website: "https://www.jura.uni-frankfurt.de/62842079/0_Goethe-Uni-Law-Clinic"
    },
    "Uni Wuerzburg": {
        name: "Chair of Computer Science VI - Artificial Intelligence and Applied Informatics",
        job: {
            "de_DE": "Julius-Maximilians-Universität Würzburg",
            "en_GB": "Julius-Maximilians-Universität Würzburg"
        },
        picture: uniWuerzburgLogo,
        website: "https://www.informatik.uni-wuerzburg.de/en/is/homepage/"
    }
}

const missingData = {
    name: "404",
    job: "Data not found."
}

function getCreditData(name, language) {
    if (!creditData.hasOwnProperty(name)) {
        console.warn(`Personal data for '${name}' is missing!`)
        return missingData;
    }

    if (!language) {
        language = "de_DE"
    }

    let data = creditData[name];
    data = _.mapValues(data, info => {
        return _.get(info, language, info)
    })

    return data;
}

export default getCreditData;