import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import './QuestionCard.css';

const QuestionCard = ({children}) => {
    return <Card className={'JunBots-Card'}>
        <Card.Body className={'JunBots-SingleQuestion-CardBody'}>
            <Container className={'JunBots-SingleQuestion-Content'}>
                <Row style={{margin: 0}}>
                    {children}
                </Row>
            </Container>
        </Card.Body>
    </Card>;
}

export default QuestionCard;