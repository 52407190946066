import React, {ReactElement, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './GeneralLayout.css';
import Toast from 'react-bootstrap/Toast'

import JunBot from "./pages/JunBot/JunBot";
import Start from "./pages/Start/Start";

import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IsMobileContext from "./contexts/IsMobileContext";
import SelectJunBots from "./pages/SelectJunBots/SelectJunBots";
import Footer from "./Footer/Footer";
import LanguageContext from "./contexts/LanguageContext";
import {IJuriCoreContext, JuriCoreContext} from "./JuriCore/JuriCoreContext";
import {BASE_URL} from "./JuriCore/Connector";
import getText from "./util/texts/getText";
import {Toaster} from "react-hot-toast";
import {ISupportiveBackendContext, SupportiveBackendContext} from "./supportive-backend/SupportiveBackendContext";
import { YouTubeIFrameAPIContext } from './contexts/YouTubeIFrameAPIContext';
import {ProjectMemberRedirect} from "./pages/ProjectMemberRedirect/ProjectMemberRedirect";

interface IPropsCustomToastContainer {
    children: ReactElement[] | ReactElement;
}

const CustomToastContainer = ({children}: IPropsCustomToastContainer) => {
    return <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        // Not sure why but this acts different than translate(-50%)
        transform: 'translateX(-50%) translateY(-50%)',
        height: 'auto',
        fontSize: '2rem',
    }}>
        {children}
    </div>
}

function App() {

    const [youTubeIFrameAPI] = useState<any>(null);

    // // Disabled due to "Abmahn" concerns
    // // Inject YouTube IFrame API
    // useEffect(() => {
    //     const tag = document.createElement('script');
    //     tag.src = "https://www.youtube.com/iframe_api";
    //     const firstScriptTag = document.getElementsByTagName('script')[0];
    //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    //     // @ts-ignore
    //     window.onYouTubeIframeAPIReady = () => {
    //         // @ts-ignore
    //         setYouTubeIFrameAPI(window.YT);
    //     }
    // }, [])

    const [juriCoreSID, setJuriCoreSID] = useState<string>(null);
    const [connectionErrorOccurred, setConnectionErrorOccurred] = useState(false);
    const [baseUrl, setBaseUrl] = useState<string>(BASE_URL);
    const [isMobile, setMobile] = useState(window.innerWidth < 780);
    const [language, setLanguage] = useState("de_DE");

    const [supportiveBackendSessionID, setSupportiveBackendSessionID] = useState<string | null>(null);

    const supportiveBackendContextDefault: ISupportiveBackendContext = {
        sessionID: supportiveBackendSessionID,
        setSessionID: setSupportiveBackendSessionID,
    }

    const juriCoreContextDefault: IJuriCoreContext = {
        sessionID: juriCoreSID,
        setSessionID: setJuriCoreSID,
        baseUrl,
        setBaseUrl,
        connectionErrorOccurred,
        setConnectionErrorOccurred
    }

    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth < parseInt(process.env.REACT_APP_MOBILE_BREAKPOINT, 10));
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize)
    }, []);

    return (
        <BrowserRouter basename={process.env.REACT_APP_REACT_ROUTER_BASE}>
            <JuriCoreContext.Provider value={juriCoreContextDefault}>
                <IsMobileContext.Provider value={isMobile}>
                    <SupportiveBackendContext.Provider value={supportiveBackendContextDefault}>
                        <YouTubeIFrameAPIContext.Provider value={youTubeIFrameAPI}>
                            <LanguageContext.Provider value={[language, setLanguage]}>
                                <Container className="App" fluid>
                                    <Row className={"Header"}>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <Container className={"Main"} fluid>
                                                <Switch>
                                                    <Route exact path={'/'}>
                                                        <Start/>
                                                    </Route>
                                                    <Route exact path='/IBelong'>
                                                        <ProjectMemberRedirect/>
                                                    </Route>
                                                    <Route exact path={'/junbots'}>
                                                        <SelectJunBots/>
                                                    </Route>
                                                    <Route path={'/junbots/:junbot'} render={({match}) => {
                                                        return <JunBot junBotGraphName={match.params.junbot}
                                                        />
                                                    }
                                                    }/>
                                                </Switch>
                                            </Container>
                                        </Col>
                                    </Row>
                                    <Row className={"Footer"}>
                                        <Col>
                                            <Footer/>
                                        </Col>
                                    </Row>
                                </Container>
                                <CustomToastContainer>
                                    <Toast show={connectionErrorOccurred} bg="danger" style={{fontSize: '1.5rem', width: '50vw'}}>
                                        <Toast.Header closeButton={false}>
                                            <strong>{getText('loadingErrorHeader', language)}</strong>
                                        </Toast.Header>
                                        <Toast.Body>{getText('loadingErrorText', language)}</Toast.Body>
                                    </Toast>
                                </CustomToastContainer>
                                { isMobile || !supportiveBackendSessionID ? null : <Toaster/> }
                            </LanguageContext.Provider>
                        </YouTubeIFrameAPIContext.Provider>
                    </SupportiveBackendContext.Provider>
                </IsMobileContext.Provider>
            </JuriCoreContext.Provider>
        </BrowserRouter>
    );
}

export default App;
