import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import {useIsProjectMember} from "../../util/LocalStorage";

export const ProjectMemberRedirect = () => {
    const history = useHistory()
    const [,setIsProjectMember] = useIsProjectMember();

    useEffect(() => {
        setIsProjectMember(true);
        setTimeout(() => {history.push('/')}, 3000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return <div>
        You are now marked as project member. You will be redirected.
    </div>
}