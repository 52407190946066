import JuriCoreError from "../errors/JuriCoreError";

function getJuriCoreInfo(baseUrl, callback) {
    let url = new URL(`${baseUrl}/info`);

    fetch(url.toString(), {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (!response.ok) throw new JuriCoreError(`error fetching general juriCore information`, response);
        response.json().then(result => callback(result))
    })
}

export default getJuriCoreInfo;