import React, {useContext} from 'react';
import Row from "react-bootstrap/Row";
import {FaGlobe, FaInstagram} from "react-icons/fa";
import Stack from "react-bootstrap/Stack";

import getCreditData from "./getCreditData";
import LanguageContext from "../../../contexts/LanguageContext";
import Col from "react-bootstrap/Col";

function IconLink({link, Icon}) {
    if (!link) {
        return <div/>
    }
    return (
        <a href={link} className={'main'} target={'_blank'} rel={'noreferrer noopener'}>
            <Icon className={'clickable'}/>
        </a>
    )
}

/**
 * This component renders the personal information of a Person. It is used in the Credits component.
 * The Personal data is referenced by the name of the Person.
 * To add a new person, goto ./getCreditData.js and add to the dictionary a new entry with the name (that should be used
 * to identify a person) as key and the corresponding values.
 * Inputting multiple languages is supported.
 *
 * @param name unique name to identify the person. It has to be a key in the dictionary of ./getCreditData.js
 * @return {JSX.Element}
 * @constructor
 */
function PersonalDetails({name}) {
    const [language,] = useContext(LanguageContext);

    let {name: displayName, picture, job, website, instagram} = getCreditData(name, language)

    return (<Stack direction={'horizontal'} gap={2} style={{alignItems: 'center'}}>
        <div style={{height: '4em', width: '4em', marginTop: '0.3em'}}>
            <a href={website} target={'_blank'} rel={'noreferrer noopener'}>
                <img src={picture} style={{maxHeight: '100%', maxWidth: '100%'}} alt={''}/>
            </a>
        </div>
        <Col>
            <Row>
                <Stack direction={'horizontal'} gap={2}>
                    <div><strong>{displayName}</strong></div>
                    {website && <IconLink Icon={FaGlobe} link={website}/>}
                    {instagram && <IconLink Icon={FaInstagram} link={instagram}/>}
                </Stack>
            </Row>
            <Row>
                {job && <div className={'long-text'}>{job}</div>}
            </Row>
        </Col>
    </Stack>)
}

export default PersonalDetails