import React, {useContext} from 'react';
import './SingleYesNoQuestion.css';
import Card from "react-bootstrap/Card";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import LanguageContext from "../../../contexts/LanguageContext";
import getText from "../../../util/texts/getText";
import LoadingButton from "../../misc/LoadingButton/LoadingButton";
import ExpandableText from "../../misc/ExpandableText/ExpandableText";
import QuestionCard from "../shared/QuestionCard/QuestionCard";
import {useYesNoSelectionFunction} from "../shared/useYesNoSelectionFunction";

const _ = require('lodash');


/**
 * A component that represents a single question of a junBot.
 * It includes:
 *  - General question (name of question question)
 *  - Further question text (expandable via a button)
 *  - Yes/No-button to answer the question
 *
 * Each question is rendered as a Card.
 *
 * @typedef {object} question - object representing a single question
 * @property {String} _id - exclusive id of this question
 * @property {{name: String, question: String | null}} values - the values of the questions. Each question should have a name.
 *              A longer question text is optional.
 *
 * @param {question} question: The question which represents this question.
 * @param setStatus: setter to update the status of a question.
 * @param activeAccordionKey: The currently active key of the accordion handling each individual question.
 * @param setActiveAccordionKey: setter to update the accordion key on expanding of this question.
 * @return {JSX.Element}
 */
function SingleYesNoQuestion({question, setStatus, activeAccordionKey, setActiveAccordionKey}) {
    const [language,] = useContext(LanguageContext);

    const {answer} = useYesNoSelectionFunction(setStatus);

    const handleClickYes = () => {
        if (_.isFunction(answer)) answer(question._id, 1)
    };
    const handleClickNo = () => {
        if (_.isFunction(answer)) answer(question._id, -1)
    };

    // the main question that is always shown
    const mainQuestion = <Card.Title style={{margin: 0}}>
        {question.mainQuestion[language] ?? question.mainQuestion.default}
    </Card.Title>

    return (
        <QuestionCard>
                <ExpandableText
                    context={{type: 'question', id: question._id}}
                    title={mainQuestion}
                    expandableContent={question.moreDetails[language] ?? question.moreDetails.default}
                    eventKey={question._key}
                    activeAccordionKey={activeAccordionKey}
                    setActiveAccordionKey={setActiveAccordionKey}
                    style={{gridAutoColumns: 'max-content'}}>
                    <div className={'JunBots-SingleQuestion-InputElement'}>
                        <ButtonGroup className={'ms-auto'}>
                            <LoadingButton
                                variant={question.status !== -1 ? 'success' : 'outline-success'}
                                onClick={handleClickYes}
                                isLoading={question.isLoading && question.status !== -1}>
                                {getText("yes", language)}
                            </LoadingButton>
                            <LoadingButton variant={question.status !== 1 ? 'danger' : 'outline-danger'}
                                           onClick={handleClickNo}
                                           isLoading={question.isLoading && question.status !== 1}>
                                {getText("no", language)}
                            </LoadingButton>
                        </ButtonGroup>
                    </div>
                </ExpandableText>
        </QuestionCard>
    )
}

export default SingleYesNoQuestion