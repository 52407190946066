import getGraph from "./getGraph";

const _ = require('lodash');

const SETTINGS_LABEL = "_settings";

/**
 * Fetches the settings of one junBot from its graphName
 *
 * @param {String} baseUrl: the base url
 * @param {String} junBotGraphName: the graphName of the requested junBot settings
 * @param {Function} callback: function is invoked with the settings-Object
 * @param {AbortController} abortController: controller that is used to signal the fetch request to stop/abort.
 */
function getJunBotSetting(baseUrl, junBotGraphName, callback, abortController) {
    getGraph(baseUrl, null, _.identity, junBotGraphName, ({nodes}) => {
        let settings = {};
        nodes.forEach(node => {
            if (node.labels.includes(SETTINGS_LABEL)) {
                _.merge(settings, node.values)
            }
        });
        callback(settings)
    }, abortController)
}

export default getJunBotSetting