import React, {useEffect, useState} from 'react';
import './ViewBots.css';
import ViewBot from "./ViewBot/ViewBot";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row"
import Connector from "../../JuriCore/Connector";

function ViewBots() {
    const [junBotsGraphNames, setJunBotsGraphNames] = useState([]);

    useEffect(() => {
        Connector.getJunBotGraphNames(setJunBotsGraphNames);
    }, []);

    return (
        <Container className={'JunBots-ViewBots'} fluid={true}>
            {junBotsGraphNames.map(junBotGraphName => {
                return <Row key={junBotGraphName}>
                    <ViewBot junBotGraphName={junBotGraphName} />
                </Row>
            })}
        </Container>
    )
}

export default ViewBots