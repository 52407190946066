import {useCallback} from "react";
import {Graph} from "../../models/graph/Graph";
import {useJuriCoreClient} from "./useJuriCoreClient";
import {SupportiveBackendConnector} from "../../supportive-backend/SupportiveBackendConnector";
import {NodeTypes} from "../../util/generalInformation";

export const useGraphAPI = () => {
    const {client} = useJuriCoreClient();
    const {createAction} = SupportiveBackendConnector.useCreateActionApi();

    const getGraph= useCallback((graphName) => {
        const response = client.get<Graph>(`/graphs/${graphName}`)

        response.then((response) => {
            const givenAnswers = response.data.nodes
                .filter(singleNode => singleNode.values.status !== 0 && singleNode.labels.includes(NodeTypes.QUESTION))
                .map(singleNode => ({id: singleNode._id, status: singleNode.values.status}));

            const receivedAnswers = response.data.nodes
                .filter(singleNode => singleNode.values.status !== 0 && singleNode.labels.includes(NodeTypes.ANSWER))
                .map(singleNode => ({id: singleNode._id, status: singleNode.values.status}));

            if(givenAnswers.length > 0 || receivedAnswers.length > 0) {
                createAction({
                    actionType: 'received-state',
                    actionPayload: {
                        givenAnswers,
                        receivedAnswers,
                    }
                });
            }
        });

        return response;
    }, [client, createAction]);

    return {
        getGraph,
    }
}