import {useContext} from "react";
import {JuriCoreContext} from "../JuriCoreContext";
import {useStandardClient} from "../../hooks/useStandardClient";
import {SupportiveBackendConnector} from "../../supportive-backend/SupportiveBackendConnector";

export const useJuriCoreClient = () => {
    const {baseUrl, sessionID, setSessionID, setConnectionErrorOccurred} = useContext(JuriCoreContext);
    const {standardClient} = useStandardClient(baseUrl)
    const {createAction} = SupportiveBackendConnector.useCreateActionApi();

    if(sessionID) {
        standardClient.defaults.headers.common['x-juricore-sid'] = sessionID;
    }

    // Updating session ID and ignore set-cookies header
    standardClient.interceptors.response.use((response) => {
        // Axios provides all headers in lower case
        // JuriCore accepts them in all lower case too
        if(sessionID !== response.headers['x-juricore-sid'])
            setSessionID(response.headers['x-juricore-sid']);
        return response;
    });

    // Updating error state
    standardClient.interceptors.response.use(null, (error) => {
        createAction({
            actionType: 'encounter-error',
            actionPayload: {
                info: 'Likely network error to the juriCore. User got red error text display in the center.',
                error
            }
        });
        setConnectionErrorOccurred(true);
    })

    return {client: standardClient};


}