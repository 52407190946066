import {useSupportiveBackendClient} from "./useSupportiveBackendClient";
import {useCallback, useContext} from "react";
import {SupportiveBackendContext} from "../SupportiveBackendContext";
import {CreateActionDto} from "../models/create-action.dto";

export const useCreateActionApi = ()  => {
    const {client} = useSupportiveBackendClient();
    const {sessionID} = useContext(SupportiveBackendContext);

    const createAction = useCallback((data: CreateActionDto) => {
        if(!sessionID) {
            return Promise.reject('No session ID given.');
        }

        return client.post(`sessions/${sessionID}/actions`, data);
    }, [sessionID, client])

    return {createAction};
}