import {useCreateActionApi} from "../../../supportive-backend/hooks/useCreateActionApi";

export const useYesNoSelectionFunction = (setStatus: (nodeID: string, newStatus: number, performNodeUpdate?: boolean) => Promise<any>) => {
    const {createAction} = useCreateActionApi();
    const interceptedSetStatus = (nodeID: string, newStatus: number, performNodeUpdate?: boolean) => {
        createAction({
            actionType: 'select-standalone-question',
            actionPayload: {
                questionId: nodeID,
                answer: newStatus,
            }
        });
        setStatus(nodeID, newStatus, performNodeUpdate);
    };

    return {
        answer: interceptedSetStatus,
    }
}