import React from "react";

export const CountryFlag = (props: {languageCode: string} & any) => {
    let countryCode = props.languageCode.split('_')[1].toLowerCase();



    let src;
    if(process.env.REACT_APP_REACT_ROUTER_BASE) {
        src = `${process.env.REACT_APP_REACT_ROUTER_BASE}/flags/${countryCode}.svg`
    } else {
        src = `/flags/${countryCode}.svg`
    }

    return <img
        src={src}
        alt={countryCode}
        {...props}
        style={{
            display: 'inline-block',
            width: '1em',
            height: '1em',
            verticalAlign: 'middle',
            ...(props.style ?? {})
        }}
    />;
}