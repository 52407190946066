import React, {useRef, useState} from 'react';
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";

function MarkdownTooltip({text, ...props}) {
    const target = useRef();
    const [show, setShow] = useState(false);

    const handleClick = () => setShow(!show);
    const handleMouseLeave = () => setShow(false);

    return (
        <>
            <u ref={target} onClick={handleClick} onMouseLeave={handleMouseLeave}>
                {props.children}
            </u>
            <Overlay show={show} target={target.current}>
                {(props) => (
                    <Tooltip id={'tooltip'} {...props}>
                        {text}
                    </Tooltip>
                )}
            </Overlay>
        </>
    )
}

export default MarkdownTooltip