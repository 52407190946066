import {useEffect, useState} from 'react';

/**
 * This is a React hook, that hooks into the dimensions of a DOM-element. The element is specified by the passed ref.
 * It returns the dimensions in a dictionary with keys 'width' and 'height'.
 * The dimensions are a state as created by React.useState().
 *
 * @param elementRef a ref (as created by React.useRef()) to the element which dimensions should be hooked into.
 * @return {{width: number, height: number}} state of the dimensions.
 */
function useElementDimensions(elementRef) {
    const [dimensions, setDimensions] = useState({width: 0, height: 0})

    useEffect(() => {
        const updateDimensions = () => {
            setDimensions({
                width: elementRef.current.offsetWidth,
                height: elementRef.current.offsetHeight
            })
        }

        if (elementRef.current) {
            updateDimensions()
        }

        window.addEventListener("resize", updateDimensions)

        return () => window.removeEventListener("resize", updateDimensions)
    }, [elementRef])

    return dimensions
}

export default useElementDimensions