import { useEffect, useRef, useState } from 'react'

export const useThrottle = <T>(value: T, throttleDuration): T => {
    const lastExecuted = useRef(Date.now())
    const [throttledValue, setThrottledValue] = useState<T>(value)

    useEffect(() => {
        if (lastExecuted.current + throttleDuration <= Date.now()) {
            lastExecuted.current = Date.now()
            setThrottledValue(value)
        } else {
            const timeoutId = setTimeout(() => {
                lastExecuted.current = Date.now()
                setThrottledValue(value)
            }, throttleDuration)

            return () => clearTimeout(timeoutId)
        }
    }, [setThrottledValue, throttleDuration, value])

    return throttledValue
}