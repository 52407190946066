import createPersistedState from 'use-persisted-state';
import {useMemo} from "react";
import {SetterFunction} from "../models/common";

export const localStorageKeyPrefixDontAskFeedbackUntil = 'dont-ask-feedback-until-';

const useDontAskFeedbackUntilRaw = (botId: string) => createPersistedState(localStorageKeyPrefixDontAskFeedbackUntil + botId)(null);

export type IUseDontAskFeedbackUntil = [Date | null, SetterFunction<Date | null>];
export const useDontAskFeedbackUntil = (botId: string): IUseDontAskFeedbackUntil => {
    const [dontAskFeedbackUntil, setDontAskFeedbackUntil] = useDontAskFeedbackUntilRaw(botId);

    const castedValue = useMemo(() => {
        if(dontAskFeedbackUntil != null) {
            // JSON parse is used under the hood. This does not generate Date objects though
            return new Date(dontAskFeedbackUntil);
        }

        return dontAskFeedbackUntil;

    }, [dontAskFeedbackUntil])

    return [
        castedValue,
        setDontAskFeedbackUntil,
    ]
}

export const localStorageKeyIsProjectMember = 'isProjectMember';

// Intermediary function to keep rules of hooks
export const useIsProjectMember = () => createPersistedState(localStorageKeyIsProjectMember)(false);