import React, {useContext} from 'react';
import "./LanguageButton.css"
import getText from "../../../util/texts/getText";
import IsMobileContext from "../../../contexts/IsMobileContext";
import {CountryFlag} from "../CountryFlag/CountryFlag";

/**
 * This component is a button that allows the selection of specific language.
 * For mobile users, the button is just the country flag.
 * Otherwise, it is the name of the language followed by the country flag.
 *
 * If no language is passed, null is returned.
 *
 * @param language the language that should be represented by this button.
 * @param setLanguage a method to change the language.
 * @return {JSX.Element}
 */
function LanguageButton({language, setLanguage}) {
    const isMobile = useContext(IsMobileContext);

    if (!language) {
        return null
    }

    let flagSize = isMobile? '1.5em' : '1.2em';
    const flag = <CountryFlag languageCode={language} style={{height: flagSize}}/>

    if (isMobile) {
        return (
            <div className={'LanguageButton clickable'} onClick={() => setLanguage(language)}>
                {flag}
            </div>
        )
    }

    return (
        <div className={'LanguageButton clickable'} onClick={() => setLanguage(language)}>
            {getText('languageName', language)}
            {flag}
        </div>
    )
}

export default LanguageButton;