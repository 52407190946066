import React from 'react';
import './Start.css'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {FaArrowRight} from "react-icons/fa"
import Description from "../../components/Texts/Description/Description";
import Disclaimer from "../../components/Texts/Disclaimer/Disclaimer";

const VIDEO_URLS = [
    "https://www.youtube.com/embed/eDjwBXiQaa8",
    "https://www.youtube.com/embed/QB_HZYflG_o",
    "https://www.youtube.com/embed/XYiQ_bEpB5g",
    "https://www.youtube.com/embed/iXYXZiDURtI",
    "https://www.youtube.com/embed/a2h3X59qtHc",
    "https://www.youtube.com/embed/EHCBUOq_2ao"
]

function Start() {
    return (
        <Container className={'Page'}>
            <Row>
                <Col className={'JunBots-Left'}/>
                <Col className={'JunBots-Middle Page-Content'} md={'auto'}>
                    <Row className={'JunBots-Headline'}>
                        <Col>
                            <h1>junBots</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Description/>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Disclaimer onlyContent={true}/>
                        </Col>
                    </Row>
                    <Row className={'JunBots-Start-Button'}>
                        <Col>
                            <Link to={'/junbots'}>
                                <Button className={'Custom-Button'} variant={'danger'}><span><FaArrowRight/> Verfügbare junBots</span></Button>
                            </Link>
                            <br/>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Container className={'Page-Content'} fluid={true}>
                                <Row className={'JunBots-Start-Videos-Headline'}>
                                    <Col>
                                        <h2>Was sind junBots und wie funktionieren sie?</h2>
                                        <br/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'JunBots-Video-List'}>
                                        {
                                            VIDEO_URLS.map(videoURL => <Row key={videoURL}>
                                                <Col className={'JunBots-Video'}>
                                                    <junit-iframe src={videoURL}
                                                            title="Vorstellung JunBots"
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen={true}
                                                            width={"100%"}
                                                            height={"100%"}/>
                                                </Col>
                                            </Row>)
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Col>
                <Col className={'JunBots-Right'}/>
            </Row>
        </Container>

    )
}

export default Start