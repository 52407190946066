import LanguageCode from "../LanguageCode";
import {TrinaryStatus} from "../common";

export interface GraphNode {
    _key: string;
    _id: string;
    isReferenceNode: boolean;
    isLoading?: boolean;
    values: GraphNodeValues;
    metaInfo: MetaInfo;
    labels: string[];
    language?: string;
}

type GraphNodeValuesI18nablePrefixes = 'displayName' | 'question' | 'name' | 'selectionText';

export type GraphNodeValues = {
    [key in `${GraphNodeValuesI18nablePrefixes}_${LanguageCode}`]?: string;
} & {
    name: string;
    displayName?: string;
    question?: string;
    selectionText?: string;
    moreSelectionText?: string;
    selectionType?: SelectionTypeTyping;
    prio?: string;

    show: boolean;
    accessible: boolean;
    status?: TrinaryStatus;
};

export type MetaInfo = {
    [key in keyof GraphNodeValues]?: MetaInfoField;
}

export interface MetaInfoField {
    dataType: 'String' | 'Boolean' | 'Number'
    mergeMethod: 'concat' | 'or' | 'and' | 'min' | 'max'
}

const SelectionTypeConst = {
    DROPDOWN: 'Dropdown',
    RADIO_BUTTON: 'RadioButton',
} as const;

export type SelectionTypeTyping = typeof SelectionTypeConst[keyof typeof SelectionTypeConst];
export const SelectionType = SelectionTypeConst as {[key in keyof typeof SelectionTypeConst]: string}