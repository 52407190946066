import React, {useContext, useState} from "react";
import {v4 as uuidv4} from "uuid";
import getText from "../../../util/texts/getText";
import {LanguageCodedKeyedObjectWithDefault} from "../../../models/common";
import LanguageContext from "../../../contexts/LanguageContext";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import './AdvancedRadioButton.css';
import { Form } from "react-bootstrap";
import {UnmountClosed} from 'react-collapse';

interface ICallbackArgs {
    isFullyOpened: boolean, // `true` only when Collapse reached final height
    isFullyClosed: boolean, // `true` only when Collapse is fully closed and height is zero
    isOpened: boolean, // `true` if Collapse has any non-zero height
    containerHeight: number, // current pixel height of Collapse container (changes until reaches `contentHeight`)
    contentHeight: number // determined height of supplied Content
}

type IProps = {
    // Will be forwarded on the radio button element
    [key: string]: any;
} & {

    /**
     * The label to be displayed next to the radio button.
     */
    label?: string;

    /**
     * The text that should be shown when expanding. If not given or the given text is empty after trim() don't show
     * any text.
     */
    expandableText?: string;

    /**
     * What text to show to prompt the user to click for more information.To be provided as language keyed object.
     * default: show more / mehr anzeigen / ...
     */
    showMoreLabel?: Partial<LanguageCodedKeyedObjectWithDefault<string>>;

    /**
     * What text to show to prompt the user to click for more information. To be provided as language keyed object.
     * default: show less / weniger anzeigen / ...
     */
    showLessLabel?: Partial<LanguageCodedKeyedObjectWithDefault<string>>;

    /**
     * Whether the expansion should be animated. Default: true
     * true = animate
     * false= don't animate
     */
    animate?: boolean;

    /**
     * Whether the radio button should be selected. Defaults to zero.
     */
    selected?: boolean;

    onRest?: (event: ICallbackArgs) => any;
    onWork?: (event: ICallbackArgs) => any;
}

const AdvancedRadioButton = ({label, expandableText, showMoreLabel, showLessLabel, animate, selected, onRest, onWork, ...props}: IProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [language] = useContext(LanguageContext);

    const uuid = uuidv4();

    const canExpand = !!expandableText?.trim()

    return <div className={'AdvancedRadioButton'}>
        <div className='AdvancedRadioButton-LeftColumn'>
            <Form.Check
                type={'radio'}
                id={uuid}
                className={'AdvancedRadioButton-RadioButton'}
                aria-label={label}
                checked={selected ?? false}
                {...props}
            />
        </div>
        <div className={'AdvancedRadioButton-RightColumn'}>
            <label htmlFor={uuid} className={'AdvancedRadioButton-Label'}>{label}</label>
            {
                canExpand
                ? <>
                        <UnmountClosed isOpened={isExpanded} onWork={onWork} onRest={onRest}>
                            <div className='ExpandableText-RadioButton'>
                                <label htmlFor={uuid} >{expandableText}</label>
                            </div>
                        </UnmountClosed>
                    <div onClick={() => setIsExpanded(!isExpanded)} className='ShowMoreLessText-LabelButton'>
                    {
                        isExpanded
                        ? <><IoIosArrowUp style={{marginRight: '0.250rem'}}/>{getShowLessLabelText(showMoreLabel, language)}</>
                        : <><IoIosArrowDown
                        style={{marginRight: '0.250rem'}}/>{getShowMoreLabelText(showMoreLabel, language)}</>
                    }
                    </div>
                </>
                : <></>
            }
        </div>
    </div>;
}

const getShowMoreLabelText = (showMoreLabel: IProps['showMoreLabel'], language: string) => {
    if(showMoreLabel) {
        return showMoreLabel[language] ?? showMoreLabel['default'];
    }

    return getText('defaultShowMoreLabel', language);
}

const getShowLessLabelText = (showLessLabel: IProps['showLessLabel'], language: string) => {
    if(showLessLabel) {
        return showLessLabel[language] ?? showLessLabel['default'];
    }

    return getText('defaultShowLessLabel', language);
}

export default AdvancedRadioButton;