import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import './Bot.css';
import Connector from "../../JuriCore/Connector";
import Container from "react-bootstrap/Container";
import Questions from "../Questions/Questions";
import Answers from "../Answers/Answers";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {FaUndo} from "react-icons/fa";
import LanguageContext from "../../contexts/LanguageContext";
import getText from "../../util/texts/getText";
import {EdgeTypes, NodeTypes} from "../../util/generalInformation";
import sortByPriority from "../../util/specialized-graph-operations/sortByPriority";
import convertGroupNodeToQuestionObject
    from "../../util/specialized-graph-operations/conversion/convertGroupNodeToQuestionObject/convertGroupNodeToQuestionObject";
import convertQuestionNodeToQuestionObject
    from "../../util/specialized-graph-operations/conversion/convertQuestionNodeToQuestionObject/convertQuestionNodeToQuestionObject";
import {Question, QuestionType} from "../../models/questions/Question";
import {ChooseOneQuestion} from "../../models/questions/ChooseOneQuestion";
import {JuriCoreContext} from "../../JuriCore/JuriCoreContext";
import {YesNoQuestion} from "../../models/questions/YesNoQuestion";
import {TrinaryStatus} from "../../models/common";
import {GraphEdge} from "../../models/graph/GraphEdge";
import {toast} from "react-hot-toast";
import {FeedbackToast} from "../FeedbackToast/FeedbackToast";
import {SupportiveBackendConnector} from "../../supportive-backend/SupportiveBackendConnector";
import {SupportiveBackendContext} from "../../supportive-backend/SupportiveBackendContext";
import {useCreateActionApi} from "../../supportive-backend/hooks/useCreateActionApi";
import {YouTubeIFrameAPIContext} from "../../contexts/YouTubeIFrameAPIContext";
import {CreateActionDto} from "../../supportive-backend/models/create-action.dto";
import IsMobileContext from "../../contexts/IsMobileContext";
import {PageEmbeddedFeedbackPrompt} from "../PageEmbeddedFeedbackPrompt/PageEmbeddedFeedbackPrompt";
import {useDontAskFeedbackUntil} from "../../util/LocalStorage";

interface IProps {
    graphName: string;
}

// How much time loading should take in ms
const enforceMinimumLoadTime = 350;

const nodeIsNotGroupMember = (nodeId: string, edges: GraphEdge[]) => {
    return edges.some(singleEdge => singleEdge.name === EdgeTypes.HAS_MEMBER && singleEdge._to === nodeId)
}


function Bot({graphName}: IProps) {
    const {setSessionID, sessionID, setConnectionErrorOccurred} = useContext(JuriCoreContext);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [answerNodes, setAnswerNodes] = useState([]);
    const [nodesShouldUpdate, setNodesShouldUpdate] = useState(true);
    const [canListenToYoutube, setCanListenToYoutube] = useState(false);
    const [previouslyLoggedState] = useState<Map<string, number>>(new Map());
    const [language, ] = useContext(LanguageContext);
    const isPristineMap = useRef<Record<string, boolean>>({});
    const {setStatus: setNodeStatus} = Connector.useSetStatusAPI();
    const {getGraph} = Connector.useGraphAPI();
    const isMobile = useContext(IsMobileContext);
    const {sessionID: supportiveBackendSessionID, setSessionID: setSupportiveBackendSessionID} = useContext(SupportiveBackendContext);

    const [nextAction, setNextAction] = useState<CreateActionDto>(null);

    const {createNewSession} = SupportiveBackendConnector.useCreateSessionApi();
    const toastTimeout = useRef<NodeJS.Timeout>(null)

    const {createAction} = useCreateActionApi();
    const YT = useContext(YouTubeIFrameAPIContext)

    const [dontAskFeedbackUntil] = useDontAskFeedbackUntil(graphName);
    const displayFeedback = useRef<boolean>(false)


    useEffect(() => {
        setSupportiveBackendSessionID(null);
        createNewSession({botId: graphName, junBotVersion: process.env.REACT_APP_VERSION});
        // console.log(dontAskFeedbackUntil.valueOf(), Date.now(), dontAskFeedbackUntil.valueOf() <= Date.now())
        displayFeedback.current = dontAskFeedbackUntil == null || dontAskFeedbackUntil.valueOf() <= Date.now();

        setConnectionErrorOccurred(false);

        return () => {
            setSupportiveBackendSessionID(null)
            setConnectionErrorOccurred(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphName]);

    // Calling createAction inside the event listener does not execute it and can apparently lead to other issues.
    // Therefore, externalized here.
    useEffect(() => {
        if(nextAction) {
            createAction(nextAction);
        }
    }, [createAction, nextAction])

    useEffect(() => {
        if (YT && canListenToYoutube) {
            const iframes = document.body.querySelectorAll('iframe');
            for (const singleIFrame of Array.from(iframes)) {
                if(!singleIFrame.id && !singleIFrame.src?.includes('enablejsapi=1')) {
                    continue;
                }
                new YT.Player(singleIFrame.id, {
                    events: {
                        'onStateChange': (e) => {
                            const state: number = e.data;
                            const playerInfo = e.target.playerInfo;

                            // 1 = playing
                            // 2 = paused
                            if([1, 2].includes(state) && previouslyLoggedState.get(singleIFrame.id) !== state) {
                                previouslyLoggedState.set(singleIFrame.id, e);
                                setNextAction({
                                    actionType: state === 1 ? 'video-start' : 'video-stop',
                                    actionPayload: {
                                        playerId: singleIFrame.id,
                                        videoId: playerInfo.videoId,
                                        currentTime: playerInfo.currentTime,
                                    }
                                });
                            }
                        }
                    }
                });
            }
        }
    }, [YT, canListenToYoutube, previouslyLoggedState])

    // This variable is to artificially prolong the loading animation to avoid flickering => smoother experience
    // null means to simply update
    const [dontRefreshNodesUntil, setDontRefreshNodesUntil] = useState<Date | null>(null);

    const assignIsPristineValueOnQuestion = useCallback((question: Question) => {
        switch (question.type) {
            case QuestionType.YES_NO:
                question.isPristine = isPristineMap.current[question._id] ?? true;
                break;
            case QuestionType.CHOOSE_ONE_RADIO_BUTTON:
            case QuestionType.CHOOSE_ONE_DROPDOWN:
                const chooseOneQuestion = question as ChooseOneQuestion;
                const groupNodePristine = isPristineMap.current[question._id] ?? true;
                const optionNodesAllPristine = chooseOneQuestion.options.every(x => isPristineMap.current[x._id] ?? true);

                question.isPristine = groupNodePristine && optionNodesAllPristine;
                break;
            default:
                throw Error('Unknown question type.');
        }

        return question;

    }, [isPristineMap]);

    useEffect(() => {
        toast.dismiss();
        if(displayFeedback.current) {
            toastTimeout.current = setTimeout(() => {
                toast((t) => <FeedbackToast toastInstance={t} botId={graphName}/>, {
                    duration: Infinity,
                    position: 'bottom-right',
                    id: 'Feedback',
                    style: {
                        maxWidth: '400px'
                    }
                })
            }, parseInt(process.env.REACT_APP_PROMPT_TIME_FEEDBACK_MS));
        }
    }, [displayFeedback, toastTimeout, graphName]);

    useEffect(() => {
        return () => {
            toast.dismiss();
            clearTimeout(toastTimeout.current);
        }

    }, [toastTimeout])

    useEffect(() => {
        if(!sessionID) {
            // This is a hack to get a session ID
            setNodeStatus('DONT_DELETE/nodes/136583273', 0);
        }
    }, [sessionID, setSessionID, setNodeStatus])

    useEffect(() => {
        if (nodesShouldUpdate) {
            if(dontRefreshNodesUntil && dontRefreshNodesUntil > new Date()) {
                const timeOut = dontRefreshNodesUntil.valueOf() - new Date().valueOf();
                setTimeout(() => {setDontRefreshNodesUntil(null)}, timeOut);
                return;
            }

            getGraph(graphName).then(({data: {nodes, edges}}) => {

                let groupNodes = [];
                let questionNodes = [];
                let answerNodes = [];
                if (nodes) {
                    nodes.forEach(node => {
                        if (node.labels.includes(NodeTypes.GROUP)) groupNodes.push(node);
                        if (node.labels.includes(NodeTypes.QUESTION) && !nodeIsNotGroupMember(node._id, edges)) questionNodes.push(node);
                        if (node.labels.includes(NodeTypes.ANSWER)) answerNodes.push(node);
                    })
                }

                const newQuestions = sortByPriority([
                    ...questionNodes.map(convertQuestionNodeToQuestionObject),
                    ...groupNodes.map(singleGroupNode => convertGroupNodeToQuestionObject({nodes, edges}, singleGroupNode)),
                ]).map(assignIsPristineValueOnQuestion);

                answerNodes = sortByPriority(answerNodes);

                setQuestions(newQuestions);
                setAnswerNodes(answerNodes);
                setCanListenToYoutube(true);
            });

            setNodesShouldUpdate(false)
        }
    }, [nodesShouldUpdate, graphName, getGraph, assignIsPristineValueOnQuestion, dontRefreshNodesUntil]);

    // The additional performNodeUpdate input is added to prevent a reload when there should be more nodes status set before
    // This is relevant in questions which offer, for example, multiple choice
    const setStatus = (nodeId: string, status: TrinaryStatus, performNodeUpdate = true) => {
        //instantly update status on local node to reduce visible lag
        let newQuestion: Question;
        setQuestions(questions.map(singleQuestion => {
            let isUnrelatedQuestion;
            switch(singleQuestion.type) {
                case QuestionType.YES_NO:
                    isUnrelatedQuestion = singleQuestion._id !== nodeId;
                    break;
                case QuestionType.CHOOSE_ONE_DROPDOWN:
                case QuestionType.CHOOSE_ONE_RADIO_BUTTON:
                    const singleChooseOneQuestion = singleQuestion as ChooseOneQuestion;
                    isUnrelatedQuestion = !singleChooseOneQuestion.options.some(singleOption => singleOption._id === nodeId)
                    break;
                default:
                    isUnrelatedQuestion = true;
            }

            if (isUnrelatedQuestion) return singleQuestion;

            newQuestion = singleQuestion;
            if((newQuestion as YesNoQuestion).status !== undefined) {
                (newQuestion as YesNoQuestion).status = status;
            }
            newQuestion.isLoading = true; // mark node as only temporary set and server response is missing.

            isPristineMap.current = {
                ...isPristineMap.current,
                [newQuestion._id]: false,
            };

            return newQuestion
        }));

        //update node status in database
        return setNodeStatus(nodeId, status).then(() => {
            if(performNodeUpdate) {
                setDontRefreshNodesUntil(new Date(new Date().valueOf() + enforceMinimumLoadTime))
                setNodesShouldUpdate(true);
            }
        });
    };

    const handleReset = () => {
        setSessionID(null);
        createAction({
            actionType: 'reset',
            actionPayload: {},
        })
        // only needed for the dev environment due it being hosted on the same subdomain.
        document.cookie= 'juricore-sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        setNodesShouldUpdate(true);
        isPristineMap.current = {};
    };

    return (
        <Container className={'JunBots-Bot'} fluid>
            <Row>
                <Container className={'JunBots-Bot-Questions'}>
                    <Questions
                        questions={questions}
                        setStatus={setStatus}/>
                </Container>
            </Row>
            <Row>
                <Container className={'JunBots-Bot-Answers'}>
                    <Answers
                        nodes={answerNodes}/>
                </Container>
            </Row>
            {
                isMobile && supportiveBackendSessionID && displayFeedback.current
                ? <Row>

                        <Container className={'JunBots-Bot-Feedback'}>
                            <PageEmbeddedFeedbackPrompt botId={graphName}/>
                        </Container>
                </Row>
                : null
            }
            <Row>
                <Container className={'JunBots-Bot-ResetButton'}>
                            <Button variant={'outline-secondary'} onClick={handleReset}>
                                <FaUndo/> {getText("resetAnswers", language)}
                            </Button>
                </Container>
            </Row>
        </Container>
    )
}

export default Bot;