import {replaceIframeWithJunitIframe} from "../../util/replace-iframe-with-junit-iframe";

const _ = require('lodash');

/**
 * This function reads a specific property from the values of a JuriCore node.
 * A language can optionally be added if this property supports multiple languages.
 * If no specific value for this property in the given language exits, the value for the default language is returned.
 *
 * This function fails silently. If the given property does not exist, null or undefined is returned. If this language
 * is not supported for this property, the default language, null or undefined is returned.
 *
 * @param nodeValues the values of the JuriCore node. Normally node.values if node is the node object returned from
 *                  a JuriCore query.
 * @param key the key of the property that is searched.
 * @param language if the specified property supports multiple languages, the value for this language is returned.
 *                  Otherwise just the value is returned.
 * @param defaultValue the value that is returned, if no value for this key exists for this node.
 * @return {*} value of the given property of the given node in the given language.
 */
function getProperty(nodeValues, key, language = null, defaultValue = '') {
    if (!nodeValues) {
        return defaultValue;
    }

    let localised_key = `${key}_${language}`;
    return replaceIframeWithJunitIframe(_.get(nodeValues, localised_key, nodeValues[key]) ?? defaultValue);
}

export default getProperty;