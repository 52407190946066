import Disclaimer_Content_de_DE from "./long/Disclaimer/Content_de_DE.md"
import Disclaimer_Content_en_GB from "./long/Disclaimer/Content_en_GB.md"
import {DISLIKE_OPTIONS} from "../../components/FeedbackToast/DISLIKE_OPTIONS";

const DEFAULT_TEXT = "No text found :/";
const DEFAULT_LANGUAGE = "de_DE";

const texts = {
    "titleOverview": {
        "de_DE": "Verfügbare junBots",
        "en_GB": "Available junBots"
    },

    "toJunbot": {
        "de_DE": "zum junBot",
        "en_GB": "to the junBot"
    },
    "backToJunbots": {
        "de_DE": "zurück zu den JunBots",
        "en_GB": "back to junBots"
    },
    "backToJunbotsSmall": {
        "de_DE": "zurück",
        "en_GB": "back"
    },
    "furtherInformationJunbot": {
        "de_DE": "Allgemeines zu den junBots",
        "en_GB": "About junBots (German)"
    },

    "noAnswerFoundTitle": {
        "de_DE": "Bisher konnte noch keine Antwort gefunden werden",
        "en_GB": "No answer could be found yet"
    },
    "noAnswerFoundBody": {
        "de_DE": "Bitte ergänzen Sie Ihre Angaben ...",
        "en_GB": "Please fill in further details ..."
    },

    "answerFoundTitle": {
        "de_DE": "Ihre Auswertungen und Empfehlungen:",
        "en_GB": "Your Results and Recommendations:"
    },

    "resetAnswers": {
        "de_DE": "Angaben zurücksetzen",
        "en_GB": "Reset information"
    },

    "yes": {
        "de_DE": "Ja",
        "en_GB": "Yes"
    },
    "no": {
        "de_DE": "Nein",
        "en_GB": "No"
    },
    "pleaseSelect": {
        "de_DE": "-- Bitte auswählen --",
        "en_GB": "-- Please Select --"
    },
    "disclaimerHeadline": {
        "de_DE": "Achtung: Dies ist ein Prototyp, der keine individuelle rechtliche Beratung ersetzt ...",
        "en_GB": "Attention: This is a prototype that does not replace individual legal advice ..."
    },
    "disclaimerContent": {
        "de_DE": (callback) => {
            fetch(Disclaimer_Content_de_DE)
                .then(response => response.text())
                .then(text => callback(text))
        },
        "en_GB": (callback) => {
            fetch(Disclaimer_Content_en_GB)
                .then(response => response.text())
                .then(text => callback(text))
        }
    },

    "creditsHeadline": {
        "de_DE": "Besonderer Dank für die Unterstützung geht an:",
        "en_GB": "Special thanks for support go to:"
    },

    "privacyPolicy": {
        "de_DE": "Datenschutzerklärung",
        "en_GB": "Privacy Policy"
    },
    "imprint": {
        "de_DE": "Impressum",
        "en_GB": "Imprint"
    },
    "contact": {
        "de_DE": "Kontakt",
        "en_GB": "Contact"
    },

    "noteOnlyGerman": {
        "de_DE": "",
        "en_GB": "(German)"
    },
    "languageName": {
        "de_DE": "Deutsch",
        "en_GB": "English"
    },
    "defaultShowMoreLabel": {
        "de_DE": "mehr anzeigen",
        "en_GB": "show more"
    },
    "defaultShowLessLabel": {
        "de_DE": "weniger anzeigen",
        "en_GB": "show less"
    },
    "loadingErrorHeader": {
        "de_DE": "Ein Fehler ist aufgetreten",
        "en_GB": "An Error Occurred"
    },
    "loadingErrorText": {
        "de_DE": "Es ist leider ein Fehler aufgetreten. Bitte laden Sie die Seite neu.",
        "en_GB": "Unfortunately, an error occurred. Please refresh the page."
    },
    "feedbackError": {
        "en_GB": "Sorry, something unexpected went wrong.",
        "de_DE": "Es ist leider ein unerwarteter Fehler aufgetreten."
    },
    "feedbackHeaderForOurResearch": {
        "en_GB": "For Our Research:",
        "de_DE": "Für unsere Forschung:"
    },
    "feedbackTextEmojis": {
        "en_GB": "Do you consider this bot to be helpful?",
        "de_DE": "Finden Sie diesen Bot hilfreich?"
    },
    "feedbackHeaderWhatDidYouDislike": {
        "en_GB": "What Did You Dislike?",
        "de_DE": "Was hat Ihnen nicht gefallen?"
    },
    "feedbackHeaderThankYou": {
        "en_GB": "Thank You!",
        "de_DE": "Vielen Dank!"
    },
    "feedbackHeaderThankYouFinal": {
        "en_GB": "Thank You for Supporting our Research!",
        "de_DE": "Vielen Dank für die Unterstützung unserer Forschung!"
    },
    "feedbackAnythingYouWannaTellUs": {
        "en_GB": "Your reply has been received! Anything else you would like to tell us?",
        "de_DE": "Wir haben Ihre Antwort erhalten! Möchten Sie uns noch etwas mitteilen?"
    },
    "feedbackPlaceholderOptional": {
        "en_GB": "(Optional)",
        "de_DE": "(Optional)"
    },
    "feedbackSendButton": {
        "en_GB": "Send",
        "de_DE": "Senden"
    },
    "bot-not-available": {
        "de_DE": "Dieser Bot steht zur Zeit nicht zur Verfügung",
        "en_GB": "This bot is currently unavailable"
    }
};

texts[DISLIKE_OPTIONS[1]] = {
    "en_GB": "information not found",
    "de_DE": "Informationen nicht gefunden"
};

texts[DISLIKE_OPTIONS[2]] = {
    "en_GB": "questions or results not understood",
    "de_DE": "Fragen / Auswertungen nicht verstanden"
};

texts[DISLIKE_OPTIONS[3]] = {
    "en_GB": "no specific recommendations",
    "de_DE": "Zu wenig konkrete Empfehlungen"
};

texts[DISLIKE_OPTIONS[4]] = {
    "en_GB": "no text input options",
    "de_DE": "Keine Texteingabemöglichkeiten"
};

texts[DISLIKE_OPTIONS[5]] = {
    "en_GB": "visually confusing",
    "de_DE": "Unübersichtlich"
};

texts[DISLIKE_OPTIONS[6]] = {
    "en_GB": "other",
    "de_DE": "Sonstiges"
};

export default function getText(key, language = DEFAULT_LANGUAGE, callback = null){
    let text;
    if(texts.hasOwnProperty(key)){
        if (texts[key].hasOwnProperty(language)) {
            text = texts[key][language]
        } else {
            text = texts[key][DEFAULT_LANGUAGE]
        }
    } else {
        text = DEFAULT_TEXT
    }

    if (text instanceof Function) {
        text(callback)
    } else {
        return text
    }
}
