
// The reason an object with numbers as keys is used is to highlight the significance of the number. They will be passed
// to the backend. In an array the order may simply be switched causally without realizing the effect.
// The value is the key for the translation
export const DISLIKE_OPTIONS = {
    1: 'dislike-option-information-not-found',
    2: 'dislike-option-question-results-not-understood',
    3: 'dislike-option-specific-recommendations-missing',
    4: 'dislike-option-not-text-input-options',
    5: 'dislike-option-visually-confusing',
    6: 'dislike-option-other',
};