import React, {useContext} from 'react';
import './Answer.css'
import Card from "react-bootstrap/Card";
import JunBotMarkdown from "../../JunbotMarkdown/JunBotMarkdown";
import ExpandableText from "../../misc/ExpandableText/ExpandableText";
import LanguageContext from "../../../contexts/LanguageContext";
import Node from "../../../JuriCore/Node";

function Answer({node}) {
    const [language,] = useContext(LanguageContext);

    let title = <Card.Subtitle>
        <div style={{color: node.values.color}}>
            <JunBotMarkdown>
                {Node.getProperty(node.values, "name", language)}
            </JunBotMarkdown>
        </div>
    </Card.Subtitle>

    return (
        <Card.Body className={'JunBots-Answer'}>
            <ExpandableText
                title={title}
                expandableContent={Node.getProperty(node.values, "answer", language)}
                eventKey={node._id}
                context={{type: 'answer', id: node._id}}
            />
        </Card.Body>
    )
}

export default Answer