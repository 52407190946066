import getGraph from "./methods/getGraph";
import setNodeStatus from "./methods/setNodeStatus";
import getJunBotGraphNames from "./methods/getJunBotGraphNames";
import getJunBotSetting from "./methods/getJunBotSetting";
import getJuriCoreInfo from "./methods/getJuriCoreInfo";
import increaseVisitCount from "./methods/increaseVisitCount";
import {SetterFunction} from "../models/common";
import {Graph} from "../models/graph/Graph";
import {useGraphAPI} from "./hooks/useGraphAPI";
import {useSetStatusAPI} from "./hooks/useSetStatusAPI";

export const BASE_URL = `${process.env.REACT_APP_JURICORE_URL}/${process.env.REACT_APP_JUNBOT_MOUNT}`;

let abortController = new AbortController();

const Connector = {
    getJunBotGraphNames: (callback) =>
        getJunBotGraphNames(BASE_URL, callback),

    getJunBotSettings: (junBotGraphName, callback) =>
        getJunBotSetting(BASE_URL, junBotGraphName, callback, abortController),

    getGraph: (juriCoreSID: string, setJuriCoreSID: SetterFunction<string>, graphName: string, callback: (graph: Graph) => void) =>
        getGraph(BASE_URL, juriCoreSID, setJuriCoreSID, graphName, callback, abortController),

    increaseVisitCount: (junBotGraphName) =>
        increaseVisitCount(BASE_URL, junBotGraphName),

    setNodeStatus: (juriCoreSID, setJuriCoreSID, nodeId, newStatus) =>
        setNodeStatus(BASE_URL, juriCoreSID, setJuriCoreSID, nodeId, newStatus),

    getJuriCoreInfo: (callback) => getJuriCoreInfo(BASE_URL, callback),

    useGraphAPI,
    useSetStatusAPI,

    /**
     * Aborts all currently active fetches.
     * NOTE: Works currently only with getJunBotSettings and getGraph.
     */
    abortAll: () => {
        abortController.abort()
        // create new AbortController as it can not be reset.
        abortController = new AbortController()
    }
}

export default Connector;