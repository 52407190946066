import React from 'react';
import './LoadingButton.css';
import Button from "react-bootstrap/Button";
import {FaSpinner} from "react-icons/fa";

/**
 * An extension of the react-bootstrap button that can be set to a loading state.
 * When the button is loading, the actual content of the button is hidden and a spinner is rotating in the center of the
 * button. The size of the button does not change!
 *
 * This button can be used as direct exchange of a bootstrap button, as every property is correctly passed down.
 *
 *
 * @param {boolean} isLoading: whether this button is currently in loading state and the spinner should spin.
 * @param {React.ReactElement | React.DOMElement} children: the children are passed down to the bootstrap button.
 * @param props: other properties that are directly passed to the bootstrap button.
 * @return {JSX.Element}
 */
function LoadingButton({isLoading, children, ...props}) {
    return (
        <Button {...props}>
            {
                isLoading && <div className={'LoadingButton-Spinner'}>
                    <FaSpinner className={'rotating'}/>
                </div>
            }
            <div className={isLoading? 'hidden' : undefined}>
                {children}
            </div>
        </Button>
    )
}

export default LoadingButton