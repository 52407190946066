import React, {useState} from 'react';
import './Questions.css';
import SingleYesNoQuestion from "./SingleYesNoQuestion/SingleYesNoQuestion";
import Accordion from "react-bootstrap/Accordion";
import SingleChooseOneDropdownQuestion from "./SingleChooseOneDropdownQuestion/SingleChooseOneDropdownQuestion";
import {QuestionType} from "../../models/questions/Question";
import SingleChooseOneRadioButtonQuestion
    from "./SingleChooseOneRadioButtonQuestion/SingleChooseOneRadioButtonQuestion";

function Questions({setStatus, questions}) {
    const [activeAccordionKey, setActiveAccordionKey] = useState();

    return (<Accordion className={'JunBots-FloatingElement'}>
        {
            questions.map(singleQuestion => {
                if (!singleQuestion.show) {
                    return <span key={singleQuestion._id}/>
                }

                switch (singleQuestion.type) {
                    case QuestionType.YES_NO:
                        return <SingleYesNoQuestion
                            key={'YesNoQuestion' + singleQuestion._id}
                            question={singleQuestion}
                            setStatus={setStatus}
                            activeAccordionKey={activeAccordionKey}
                            setActiveAccordionKey={setActiveAccordionKey}
                        />;
                    case QuestionType.CHOOSE_ONE_DROPDOWN:
                        return <SingleChooseOneDropdownQuestion
                            key={'ChooseOneDropdownQuestion' + singleQuestion._id}
                            keyId={'ChooseOneDropdownQuestion' + singleQuestion._id}
                            question={singleQuestion}
                            setStatus={setStatus}
                            activeAccordionKey={activeAccordionKey}
                            setActiveAccordionKey={setActiveAccordionKey}
                        />;
                    case QuestionType.CHOOSE_ONE_RADIO_BUTTON:
                        return <SingleChooseOneRadioButtonQuestion
                            key={'ChooseOneRadioButtonQuestion' + singleQuestion._id}
                            keyId={'ChooseOneRadioButtonQuestion' + singleQuestion._id}
                            question={singleQuestion}
                            setStatus={setStatus}
                            activeAccordionKey={activeAccordionKey}
                            setActiveAccordionKey={setActiveAccordionKey}
                        />
                    default:
                        console.warn('No fitting question type was found. Given question type was ' + singleQuestion.type);
                }

                return <></>
            })
        }
    </Accordion>)
}

export default Questions;