import React, {useContext} from 'react';
import './ExpandableText.css'

import {useAccordionButton} from "react-bootstrap/AccordionButton";
import ExpandButton from "../ExpandButton/ExpandButton";
import AccordionCollapse from "react-bootstrap/AccordionCollapse";
import JunBotMarkdown from "../../JunbotMarkdown/JunBotMarkdown";
import {AccordionContext} from "react-bootstrap";
import {SupportiveBackendConnector} from "../../../supportive-backend/SupportiveBackendConnector";

const _ = require('lodash');


type IProps = {
    [key: string]: any;
} & {
    title: JSX.Element,
    expandableContent?: string | null,
    eventKey: string,
    children?: JSX.Element,
    context?: {
        type: 'question' | 'option' | 'answer',
        id: string,
    };
}

/**
 * Wrapper to get the active key from the accordion.
 * It is used to customize the layout of the accordion items.
 *
 * @param children: The content of the button. It is wrapped in a Card.Title.
 * @param eventKey: eventKey of this toggle
 * @param context: Indicates what sort of element and instance is being expanded / collapsed. This is used for determining the action type.
 * @return {*}
 * @constructor
 */
function CustomAccordionButton({children, eventKey, context}) {
    const { activeEventKey } = useContext(AccordionContext);
    const {createAction} = SupportiveBackendConnector.useCreateActionApi();
    const trackToggle = () => {
        if(context) {

            const assuredEventKey = activeEventKey ?? ''
            createAction({
                actionType: assuredEventKey === eventKey || (Array.isArray(assuredEventKey) && assuredEventKey.includes(eventKey)) ? `collapse-${context.type}` : `expand-${context.type}`,
                actionPayload: {
                    id: context.id,
                }
            })
        }
    }

    const decoratedAccordionToggle = useAccordionButton(eventKey, trackToggle);

    return (
        <div onClick={decoratedAccordionToggle}>
            {children}
        </div>
    )
}


function OptionalAccordionButton({active, children, eventKey, context}) {
    if (!active) {
        return children;
    }
    return (
        <CustomAccordionButton
            context={context}
            eventKey={eventKey}>
            {children}
        </CustomAccordionButton>
    )
}


/**
 * This component is a customization of the Accordion.Item component of react-bootstrap.
 * NOTE: It should be used as child of an Accordion component!
 *
 * It represents a Text component, that has a title and further content. The further content can be expanded by
 * clicking on the title or a button next to the title. It is wrapped inside a JunBotMarkdown component.
 * It uses css grid for the layout. The grid is as follows defined:
 *
 * | <expand-button> | <title>               |
 * |                 | <expandable-content>  |
 *
 * For further customization, this component can have children, that are rendered inside the grid.
 * Hence, you can specify the positions of the children inside the grid with the css attributes 'grid-column-start',
 * 'grid-row-start', ...
 *
 * Example:
 * <ExpandableText ...>
 *     <div style={{gridColumnStart: 3, gridRowStart: 1}}>Custom Text</div>
 * </ExpandableText>
 *
 * renders as:
 * | <expand-button> | <title>               | Custom Text
 * |                 | <expandable-content>  |
 *
 * @param {String | JSX.Element} title: The element that should always be displayed as title of this accordion element.
 * @param {String} expandableContent: The content that should be additionally added when this element is expanded.
 *                                     It is wrapped inside a JunBotMarkdown component => Markdown supported.
 * @param eventKey: A unique key to map between the accordion toggle and the content. It is used as accordion key.
 * @param children: optional children, that are rendered inside the grid. By specifying grid positioning css attributes
 *                  for each child, the positioning can individually manipulated.
 * @param context: Indicates what sort of element and instance is being expanded / collapsed. This is used for determining the action type.
 * @param props: other props that are directly passed to the grid container.
 * @return {JSX.Element}
 */
function ExpandableText({
                            title,
                            expandableContent,
                            eventKey,
                            children,
                            context,
                            ...props
                        }: IProps) {
    const {activeEventKey} = useContext(AccordionContext)

    // Checking if the trim is truthy to avoid whitespace character declaring the text expandable
    let isExpandable = _.isString(expandableContent) && expandableContent.trim();

    // checking if this element is active
    // if "alwaysOpen" is active on the parent Accordion, the activeEventKey is an array containing all the active keys
    let isExpanded = activeEventKey === eventKey || _.indexOf(activeEventKey, eventKey) >= 0

    return (
        <div className={'ExpandableText-grid'} {...props}>
            <div className={isExpandable ? 'ExpandableText-ExpandButton expandable' : ''}>
                <OptionalAccordionButton
                    context={context}
                    active={isExpandable}
                    eventKey={eventKey}>
                    <ExpandButton
                        isExpanded={isExpanded}
                        className={isExpandable ? '' : 'hidden'}/>
                </OptionalAccordionButton>
            </div>
            <div className={isExpandable ? 'ExpandableText-title expandable' : 'ExpandableText-title'}>
                <OptionalAccordionButton
                    context={context}
                    active={isExpandable}
                    eventKey={eventKey}>
                    <div>{title}</div>
                </OptionalAccordionButton>
            </div>
            <div className={'ExpandableText-expandable-content'}>
                <AccordionCollapse eventKey={eventKey}>
                    <JunBotMarkdown className={'long-text'}>
                        {expandableContent}
                    </JunBotMarkdown>
                </AccordionCollapse>
            </div>
            {children}
        </div>
    )
}

export default ExpandableText;