import React, {ReactElement, useContext, useRef, useState} from "react";
import {
    AiOutlineCheck,
    AiOutlineWarning,
    RiCloseFill,
    RiEmotionHappyLine,
    RiEmotionNormalLine,
    RiEmotionUnhappyLine
} from "react-icons/all";
import './FeedbackToast.css';
import {Toast as ToastObject, toast} from "react-hot-toast";
import {SupportiveBackendConnector} from "../../supportive-backend/SupportiveBackendConnector";
import {LoadingIndicator} from "../LoadingIndicator/LoadingIndicator";
import { Button, Form } from "react-bootstrap";
import {DISLIKE_OPTIONS} from "./DISLIKE_OPTIONS";
import IsMobileContext from "../../contexts/IsMobileContext";
import {useThrottle} from "../../hooks/useThrottle";
import {useDontAskFeedbackUntil} from "../../util/LocalStorage";
import LanguageContext from "../../contexts/LanguageContext";
import getText from "../../util/texts/getText";

const emojiSize = 70;

interface IProps {
    toastInstance?: ToastObject;
    botId: string
}

export const FeedbackToast = ({toastInstance, botId}: IProps) => {
    const [header, setHeader] = useState<ReactElement>(<></>);
    const isMobile = useContext(IsMobileContext);

    const dismissFeedbackToast = () => toastInstance && toast.dismiss(toastInstance.id);

    return <div className='FeedbackToastContentContainer'>

        <div className='FeedbackHeader'>
            {header}{ isMobile ? null : <RiCloseFill size={25} className='FeedbackToastCloseButton' onClick={() => dismissFeedbackToast()}/> }
        </div>
        <FeedbackToastBody closeToast={() => dismissFeedbackToast() } botId={botId} setHeader={(newHeader) => newHeader.key !== header.key ? setHeader(newHeader) : () => {}}/>
    </div>
}

const FeedbackToastBody = ({closeToast, setHeader, botId}) => {

    const {partiallyUpdateSession, loading, error} = SupportiveBackendConnector.usePartialUpdateSessionApi();
    const [, setDontAskFeedbackUntil] = useDontAskFeedbackUntil(botId);

    const [fakeLoading, setFakeLoading] = useState(false)
    const throttledLoading = useThrottle(loading, 600);

    const [step, setStep] = useState(1);
    const feedbackMessageTextareaRef = useRef<HTMLTextAreaElement>();

    const [language] = useContext(LanguageContext);

    const sendMessage = async () => {
        const feedbackMessage = feedbackMessageTextareaRef.current.value;
        if(feedbackMessage) {
            await partiallyUpdateSession({feedbackMessage});
            setStep(4);
        } else {
            setFakeLoading(true);
            setTimeout(() => {setStep(4); setFakeLoading(false)}, 600)
        }
    }

    const sendDislikes = async () => {
        const chosenOptionsInputs = document.body.querySelectorAll('input[name="feedback-dislike-options"]');
        const reasonsForDislike = Array.from(chosenOptionsInputs)
            .filter((x: HTMLInputElement) => x.checked)
            .map((x: HTMLInputElement) => parseInt(x.value));

        await partiallyUpdateSession({reasonsForDislike});
        setStep(3);
    }

    const rateBot = async (rating: number) => {
        await partiallyUpdateSession({rating});

        const inSevenDays = new Date();

        // automatically wraps months ends; see https://stackoverflow.com/questions/563406/how-to-add-days-to-date#563442
        inSevenDays.setDate(inSevenDays.getDate() + 7);
        setDontAskFeedbackUntil(inSevenDays);

        if(rating === 1) {
            // Skipping reasons for dislike
            setStep(3)
        } else {
            setStep(2);
        }
    }

    if(error) {
        return <div><AiOutlineWarning />{getText('feedbackError', language)}</div>
    }

    if(throttledLoading || fakeLoading) {
        return <div style={{textAlign: 'center'}}>
            <LoadingIndicator style={{fontSize: emojiSize + 'px'}} />
        </div>;
    }

    switch (step) {
        case 1:
            setHeader(<h5 key={'forOurResearch' + language}>{getText('feedbackHeaderForOurResearch', language)}</h5>);
            return <>
                <h4 className="mb-4" style={{fontWeight: 'bold'}}>{getText('feedbackTextEmojis', language)}</h4>
                <div className='FeedbackOptionEmojis'>
                    <RiEmotionHappyLine className='OptionEmojiGreen' onClick={() => rateBot(1)}
                                        size={emojiSize}/>
                    <RiEmotionNormalLine className='OptionEmojiOrange'
                                         onClick={() => rateBot(0)}
                                         size={emojiSize}/>
                    <RiEmotionUnhappyLine className='OptionEmojiRed' onClick={() => rateBot(-1)}
                                          size={emojiSize}/>
                </div>
            </>;
        case 2:
            setHeader(<h4 key={'whatDidYouDislike' + language}>{getText('feedbackHeaderWhatDidYouDislike', language)}</h4>)
            const options: ReactElement[] = [];
            for(const singleOptionKey in DISLIKE_OPTIONS) {
                options.push(<Form.Check
                    value={singleOptionKey}
                    type={'checkbox'}
                    label={getText(DISLIKE_OPTIONS[singleOptionKey], language)}
                    name='feedback-dislike-options'
                    id={`feedback-dislike-option-${singleOptionKey}`}
                />)
            }

            return <div>
                {options}
                <div style={{textAlign: 'right'}}>
                    <Button style={{marginTop: '8px'}} variant="primary"  onClick={() => sendDislikes() }>
                        {getText('feedbackSendButton', language)}
                    </Button>
                </div>
            </div>

        case 3:
            setHeader(<h4 key={'thankYou' + language}>{getText('feedbackHeaderThankYou', language)}</h4>)
            return <div>
                {getText('feedbackAnythingYouWannaTellUs', language)}
                <textarea style={{width: '100%', resize: 'none', marginTop: '12px'}} rows={4} ref={feedbackMessageTextareaRef} placeholder={getText('feedbackPlaceholderOptional', language)}/>
                <div style={{textAlign: 'right'}}>
                    <Button style={{marginTop: '8px'}} variant="primary"  onClick={() => sendMessage() }>
                        {getText('feedbackSendButton', language)}
                    </Button>
                </div>
            </div>
        case 4:
            setHeader(<h4 key={'thankYouFinal' + language}>{getText('feedbackHeaderThankYouFinal', language)}</h4>)
            setTimeout(() => {closeToast()}, 7000)
            return <div style={{textAlign: 'center'}}>
                <AiOutlineCheck fill='#3ce13c' size={emojiSize}/>
            </div>

    }
}