import axios from "axios";
import axiosRetry from "axios-retry";

export const useStandardClient = (baseUrl: string ) => {
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    const axiosInstance = axios.create({baseURL: baseUrl, headers: defaultHeaders});

    axiosRetry(axiosInstance, {retries: 2, retryDelay: axiosRetry.exponentialDelay});

    return {standardClient: axiosInstance};

}