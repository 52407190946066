import {EdgeTypes} from "../generalInformation";
import sortByPriority from "./sortByPriority";
import {Graph} from "../../models/graph/Graph";
import {GraphNode} from "../../models/graph/GraphNode";

/**
 * Return for a given graph and node id the members of the group in the order of priority. Does not check the type of
 * the nodes but assumes the correct types.
 *
 * @param graph The graph in question
 * @param groupNodeId The id of the group node
 * @returns {*[]} The nodes connected to the grouping node with the given order of priority.
 */
const getQuestionsByGroup = (graph: Graph, groupNodeId: string): GraphNode[] => {
    // Obtaining memberOf edges with the given group node as source
    const relevantEdges = graph.edges
        .filter(singleEdge =>
            singleEdge._from === groupNodeId
            && singleEdge.name.includes(EdgeTypes.HAS_MEMBER)
        );

    // Retrieve the node object from the graph via the id
    const groupedNodes  = relevantEdges
        .map(singleEdge => graph.nodes.find(singleNode => singleNode._id === singleEdge._to));

    return sortByPriority(groupedNodes);
}

export default getQuestionsByGroup;